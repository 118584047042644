import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AlertsService } from '../services/alerts.service';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { JobChangeAlertService } from '../services/job-change-alert.service';
import { KeyClickService } from '../services/keyclick.service';
import { RedactedService } from '../services/redacted.service';
import { SidebarService } from '../services/sidebar.service';
import { SignoutService } from '../services/signout.service';
import { SkillsCheckService } from '../services/skills-check.service';
import { SpinnerService } from '../services/spinner.service';
import { TestJobsService } from '../services/test-jobs.service';
import { TransferService } from '../services/transfer.service';
import { isSmallScreenWidth } from '../utils/screens';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class MainComponent implements OnDestroy, OnInit {
  @ViewChild('confirmLogoutSwal', { static: false }) swal: SwalComponent;

  agent$ = this.authService.agent$;
  sidebarIsClosed$ = this.sidebarService.sidebarIsClosed$;

  get isSmallScreenWidth() {
    return isSmallScreenWidth();
  }

  constructor(
    public alertsService: AlertsService,
    public authService: AuthService,
    public jobChangeAlertService: JobChangeAlertService,
    public redactedService: RedactedService,
    public spinnerService: SpinnerService,
    public testJobsService: TestJobsService,
    public transferService: TransferService,
    private convsService: ConvsService,
    private keyClickService: KeyClickService,
    private sidebarService: SidebarService,
    private signoutService: SignoutService,
    private skillsCheckService: SkillsCheckService
  ) {}

  ngOnDestroy() {
    console.log('Main component destroy');
    this.stopKeyClickListeners();
  }

  ngOnInit() {
    console.log('Main component init');
    this.handleKeyClickDown = this.handleKeyClickDown.bind(this);
    this.handleKeyClickUp = this.handleKeyClickUp.bind(this);

    this.startKeyClickListeners();

    this.skillsCheckService.startSkillsCheckMonitorForGlobalAgents();
  }

  onLogout() {
    if (this.convsService.hasDraftsRemaining()) {
      return this.swal.fire().then(response => {
        if (response.isConfirmed) {
          this.signoutService.signout();
        }
      });
    } else {
      this.signoutService.signout();
    }
  }

  private handleKeyClickDown(event) {
    this.keyClickService.handleKeyClick(event);
  }

  private handleKeyClickUp(event) {
    this.keyClickService.handleKeyClickUp(event);
  }

  private startKeyClickListeners() {
    document.addEventListener('keydown', this.handleKeyClickDown);
    document.addEventListener('keyup', this.handleKeyClickUp);
  }

  private stopKeyClickListeners() {
    document.removeEventListener('keydown', this.handleKeyClickDown);
    document.removeEventListener('keyup', this.handleKeyClickUp);
  }
}
