import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AiSuggestion, AiSuggestionText } from '../models/ai';
import { EventType, MMSEvent, TextEvent } from '../models/event';
import { RedactedService } from './../services/redacted.service';

import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';
import { TemplateMedia } from '../models/job';
import { Lead } from '../models/leads';
import { AlertsService } from '../services/alerts.service';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { DomainService } from '../services/domain.service';
import { JobsService } from '../services/jobs.service';
import { KeyClickService } from '../services/keyclick.service';
import { ResponderService } from '../services/responder.service';
import { TestJobsService } from '../services/test-jobs.service';
import { deepEquals } from '../utils/deepEquals';
import { isMobile } from '../utils/screens';

@Component({
  selector: 'app-conv-actions-footer-default',
  templateUrl: './conv-actions-footer-default.component.html',
  styleUrls: ['./conv-actions-footer-default.component.scss']
})
export class ConvActionsFooterDefaultComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @ViewChild('inputRow', { static: true }) inputRow: ElementRef;
  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  @Output() gotoEvent = new EventEmitter<string>();
  @Output() aiSuggestedTemplate = new EventEmitter<string>(); // emits template id
  @Output() aiSuggestedAnswer = new EventEmitter<{
    question_id: string;
    answer: string;
  }>();
  @Output() textEvent = new EventEmitter<TextEvent | MMSEvent>();

  @Input() aiSuggestion: AiSuggestion;
  @Input() lead: Lead;
  @Input() sendButtonStatus: string;

  aiSuggestionText: AiSuggestionText = null;
  data = { canEditMessages: false, canSendMessages: true };
  emojiPickerIsOpen = false;
  sendAndNewMouseDownTimer: NodeJS.Timeout;
  isCollapsed = true;
  subs: Subscription[] = [];
  sendAndNewClickAndHold = false;
  text: FormControl;
  templateText: string;
  templateMedia: TemplateMedia;

  canEditMessages$ = combineLatest([
    this.domainService.systemConfigs,
    this.responderService.currentAgentIsResponder
  ]).pipe(
    map(([config, currentAgentIsResponder]) => {
      if (config.can_edit_messages === 'ALL') {
        return true;
      } else if (config.can_edit_messages === 'RESPONDERS' && currentAgentIsResponder) {
        return true;
      }

      return false;
    })
  );

  canSendMessages$ = this.convsService.canSendMessages$;

  clearTemplate$ = this.convsService.clearTemplate$.pipe(
    tap(template => {
      if (template === this.text.value) {
        this.injectTemplate('');
      }
    })
  );

  focusOnRouting$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    tap(e => {
      if (!isMobile()) {
        this.textarea.nativeElement.focus();
      }
    })
  );

  constructor(
    public authService: AuthService,
    public jobsService: JobsService,
    public testJobsService: TestJobsService,
    private alertService: AlertsService,
    private convsService: ConvsService,
    private cd: ChangeDetectorRef,
    private domainService: DomainService,
    private responderService: ResponderService,
    private keyClickService: KeyClickService,
    private modalService: NgbModal,
    private router: Router,
    public redactedService: RedactedService
  ) {}

  get sendButtonText() {
    if (this.text.value) {
      if (this.sendButtonStatus === 'new') {
        return 'sendAndNew';
      }
      return 'sendAndNext';
    } else {
      if (this.sendButtonStatus === 'new') {
        return 'new';
      }
      return 'next';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.aiSuggestion) {
      this.setAiSuggestionText(changes.aiSuggestion.currentValue);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => {
      if (s) {
        s.unsubscribe();
      }
    });

    document.removeEventListener('keydown', this.handleKeyClick);
  }

  ngOnInit() {
    this.handleKeyClick = this.handleKeyClick.bind(this);
    this.buildForm();
    this.autoResizeTextarea();
    this.startKeyClickListener();
    this.subscribeToDataStreams();
  }

  ngAfterViewInit() {
    this.forceTextareaResize();
  }

  clearMedia() {
    this.templateMedia = null;
    this.forceTextareaResize();
  }

  injectTemplateText(text: string) {
    this.templateText = text;
    this.text.reset(text);

    if (this.redactedService.shouldRedact) {
      console.log('Injecting redacted text');
      const el = document.getElementById('redacted-textarea') as HTMLTextAreaElement;
      if (el) {
        el.value = 'Message Text Hidden';
      }
    }

    if (!isMobile() && this.textarea) {
      this.textarea.nativeElement.focus();
    }
  }

  injectTemplate(text: string, media: TemplateMedia = null) {
    this.injectTemplateText(text);
    this.templateMedia = media;
    this.forceTextareaResize();
  }

  onGoTo(comp: string) {
    this.gotoEvent.emit(comp);
  }

  onSendMessage(thenStartNew: boolean = false) {
    if (!this.convsService.canSendMessage()) {
      console.log('Conv service is not ready');
      return;
    }

    if (this.text.value && this.text.valid) {
      const txt = this.stripAndValidateMessage(this.text);
      if (!txt) {
        return;
      }

      if (this.templateMedia) {
        console.log('Template has media', this.templateMedia);
        if (this.templateMedia.media_id) {
          this.textEvent.emit({
            messageType: EventType.mmsEvent,
            text: txt,
            thenStartNew,
            media_id: this.templateMedia.media_id,
            preview_url: this.templateMedia.preview_url,
            thumbnail_url: this.templateMedia.thumbnail_url,
            media_type: this.templateMedia.media_type,
            title: this.templateMedia.title
          } as MMSEvent);
        } else {
          this.textEvent.emit({
            messageType: EventType.mmsEvent,
            text: txt,
            thenStartNew,
            media: this.templateMedia.url,
            media_type: 'IMAGE',
            title: this.templateMedia.title
          } as MMSEvent);
        }
      } else {
        this.textEvent.emit({
          messageType: EventType.textEvent,
          text: txt,
          thenStartNew
        } as TextEvent);
      }

      this.resetForm();
    } else if (!this.text.value && thenStartNew) {
      this.convsService.startNewConv(this.sendButtonStatus);
    }
  }

  openEmojiPicker() {
    if (this.aiSuggestionText) {
      return;
    }

    this.emojiPickerIsOpen = true;
  }

  onEmojiSelected(emoji: EmojiData) {
    const input = this.textarea.nativeElement;
    input.focus();

    if (document.execCommand) {
      const event = new Event('input');
      document.execCommand('insertText', false, emoji.native);
    } else {
      const [start, end] = [input.selectionStart, input.selectionEnd];
      input.setRangeText(emoji.native, start, end, 'end');
    }
    this.emojiPickerIsOpen = false;
  }

  previewMedia() {
    if (!this.templateMedia) {
      return;
    }

    const modalRef = this.modalService.open(MediaPreviewComponent, {
      centered: true,
      windowClass: 'media-preview-modal'
    });
    this.setModalPreviewValues(modalRef.componentInstance);
  }

  resetForm(text: string = null) {
    this.text.reset(text);
    this.forceTextareaResize();
    this.clearMedia();
  }

  onDismissAiSuggestion() {
    this.aiSuggestionText = null;
  }

  onAcceptAiSuggestion() {
    this.aiSuggestionText = null;

    if (this.aiSuggestion.type === 'question') {
      this.aiSuggestedAnswer.emit({
        question_id: this.aiSuggestion.question_id,
        answer: this.aiSuggestion.answer
      });
    } else if (this.aiSuggestion.type === 'template') {
      this.aiSuggestedTemplate.emit(this.aiSuggestion.template_id);
    }
  }

  private aiSuggestionIsDifferent(suggestion: SimpleChange) {
    if (suggestion.firstChange) {
      return true;
    }

    if (deepEquals(suggestion.currentValue, suggestion.previousValue)) {
      return false;
    }

    return true;
  }

  private autoResizeTextarea() {
    if (this.textarea) {
      this.textarea.nativeElement.addEventListener('input', () => this.forceTextareaResize(), false);
    }
  }

  private buildForm() {
    this.text = new FormControl(this.templateText, [Validators.required]);
  }

  private forceTextareaResize() {
    if (this.textarea) {
      this.textarea.nativeElement.style.height = `auto`;
      this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
      this.cd.detectChanges();
    }
  }

  private handleKeyClick(event: KeyboardEvent) {
    this.keyClickService.handleKeyClick(event);
    const sendAndNewShortcut = this.keyClickService.hasSendAndNewShortcut();
    const enterAndNotShift = this.keyClickService.clickedKey('Enter') && !this.keyClickService.clickedKey('Shift');

    if (enterAndNotShift || sendAndNewShortcut) {
      this.onSendMessage(sendAndNewShortcut);
      event.preventDefault();
    } else if (this.keyClickService.clickedKey('Control') && this.keyClickService.clickedKey('Shift')) {
      event.preventDefault();
    }
  }

  private setAiSuggestionText(suggestion: AiSuggestion | null | undefined) {
    if (!suggestion) {
      this.aiSuggestionText = null;
      return;
    }

    if (suggestion.type === 'question') {
      const questionText = this.convsService.getCurrentConversationQuestionTextByQuestionId(suggestion.question_id);

      this.aiSuggestionText = {
        header: 'aiSuggestions.questionHeader',
        body: 'aiSuggestions.questionBody',
        bodyparams: {
          questionText,
          answer: suggestion.answer
        },
        footer: 'aiSuggestions.questionFooter',
        acceptButtonText: 'aiSuggestions.questionAcceptButton'
      };
    } else if (suggestion.type === 'template') {
      const template = this.jobsService.getTemplateById(suggestion.template_id);

      this.aiSuggestionText = {
        header: 'aiSuggestions.templateHeader',
        body: 'aiSuggestions.templateBody',
        bodyparams: {
          templateText: template.text
        },
        footer: 'aiSuggestions.templateFooter',
        acceptButtonText: 'aiSuggestions.templateAcceptButton'
      };
    }
  }

  private setModalPreviewValues(comp) {
    comp.type = this.templateMedia.media_id ? this.templateMedia.media_type : 'IMAGE';
    comp.url = this.templateMedia.media_id ? this.templateMedia.preview_url : this.templateMedia.url;
    comp.title = this.templateMedia.title;
  }

  private startKeyClickListener() {
    document.addEventListener('keydown', this.handleKeyClick);
  }

  private stripAndValidateMessage(text: FormControl): string | null {
    const txt = text.value.trim();
    if (!txt) {
      this.alertService.setMessage('alert.blankMessage');
      return null;
    }
    return txt;
  }

  private subscribeToDataStreams() {
    this.subs.push(this.focusOnRouting$.subscribe());

    this.subs.push(
      combineLatest([this.canEditMessages$, this.canSendMessages$, this.clearTemplate$]).subscribe(
        ([canEditMessages, canSendMessages, clearTemplate]) => {
          this.data = {
            canEditMessages,
            canSendMessages
          };
        }
      )
    );
  }
}
