import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConvSidebarQuestionsComponent } from '../conv-sidebar-questions/conv-sidebar-questions.component';
import { Conversation } from '../models/conversation';
import { Template } from '../models/job';
import { Lead } from '../models/leads';
import { ConvsService } from '../services/convs.service';
import { EventMenuService } from '../services/event-menu.service';
import { RedactedService } from '../services/redacted.service';

@Component({
  selector: 'app-conv-sidebar',
  templateUrl: './conv-sidebar.component.html',
  styleUrls: ['./conv-sidebar.component.scss']
})
export class ConvSidebarComponent implements OnChanges, OnInit {
  eventMenuSelection$ = this.eventMenuService.eventMenuSelection$.pipe(
    map(flag => {
      if (flag && this.tabset) {
        this.tabset.select('questions');
      }
      return true;
    })
  );

  lead$: Observable<Lead> = this.convsService.currentConversation$.pipe(
    filter(Boolean),
    map((conv: Conversation) => {
      if (this.redactedService.shouldRedact) {
        return { lead_phone: conv.lead.lead_phone.slice(0, -4) + 'XXXX' } as Lead;
      }

      return conv.lead;
    })
  );

  leadTabData$ = this.lead$.pipe(
    map(lead => {
      const auxData = this.getAuxData(lead);
      return { lead, auxData };
    })
  );

  @ViewChild('nav') tabset: NgbNav;

  @ViewChild(ConvSidebarQuestionsComponent)
  sidebarQuestions: ConvSidebarQuestionsComponent;

  @Output() templateSelected = new EventEmitter<Template>();

  @Input() hasLeadResponse = false;

  @Input() conversation: Conversation;

  activeTab;

  constructor(
    private convsService: ConvsService,
    private eventMenuService: EventMenuService,
    public redactedService: RedactedService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasLeadResponse?.currentValue) {
      this.activeTab = 'questions';
    } else {
      this.activeTab = 'details';
    }
  }

  ngOnInit() {}

  onTemplateSelection(t: Template) {
    this.templateSelected.emit(t);
  }

  private getAuxData(lead: Lead): string[] {
    const auxData = [];
    if (lead.extern_id) {
      auxData.push(lead.extern_id);
    }
    if (lead.aux_data1) {
      auxData.push(lead.aux_data1);
    }
    if (lead.aux_data2) {
      auxData.push(lead.aux_data2);
    }
    if (lead.aux_data3) {
      auxData.push(lead.aux_data3);
    }
    if (lead.aux_data4) {
      auxData.push(lead.aux_data4);
    }
    if (lead.aux_data5) {
      auxData.push(lead.aux_data5);
    }
    return auxData;
  }
}
