import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, switchMap } from 'rxjs/operators';
import { JobChangeAlertModalComponent } from '../job-change-alert-modal/job-change-alert-modal.component';
import { AudioService } from './audio.service';
import { ConvsService } from './convs.service';
import { JobsService } from './jobs.service';
import { KeyClickService } from './keyclick.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class JobChangeAlertService {
  private currentJobId: string = null;

  constructor(
    private audioService: AudioService,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private keyclickService: KeyClickService,
    private modalService: NgbModal,
    private spinnerService: SpinnerService
  ) {
    this.init();
  }

  init() {
    this.jobsService.currentJobId$
      .pipe(
        switchMap(jobId =>
          this.convsService.isFastModeEnabled$.pipe(map(isFastModeEnabled => ({ jobId, isFastModeEnabled })))
        )
      )
      .subscribe(({ jobId, isFastModeEnabled }) => {
        if (!this.currentJobId) {
          this.currentJobId = jobId;
          return;
        }

        if (isFastModeEnabled && this.currentJobId !== jobId) {
          // Show a job change alert modal
          this.playDing();
          const modal = this.showJobChangeAlertModal(jobId);
          modal.result.then(() => {
            this.currentJobId = jobId;

            if (this.spinnerService.isSpinning) {
              this.spinnerService.stopSpinner();
            }
          });
        } else if (this.currentJobId !== jobId) {
          this.currentJobId = jobId;
        }
      });
  }

  private playDing() {
    this.audioService.playDing();
    setTimeout(() => {
      if (this.keyclickService.hasSendAndNewShortcut()) {
        this.playDing();
      }
    }, 500);
  }

  private showJobChangeAlertModal(jobId) {
    return this.modalService.open(JobChangeAlertModalComponent, {
      centered: true,
      backdrop: 'static'
    });
  }
}
